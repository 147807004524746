<template>
  <section class="core_c">
    <notification_popup></notification_popup>
    <error_popup_queue></error_popup_queue>
    <outside_banner_std></outside_banner_std>

    <div class="flex_centered_row">
      <div class="flex_fully_centered_column margin_t_40 main_column">

        <h4 class="margin_b_20 login text_align_center">Confirm Password Reset</h4>
        <input_with_label
            ref="pw_reset_username_box"
            class="login_input"
            label="Your Username"
            @emit_state="catch_pw_reset_username"
        ></input_with_label>


        <input_with_label
            ref="pw_reset_new_password_box"
            class="login_input"
            label="Your New Password 8+ Chars"
            @emit_state="catch_pw_reset_new_password"
        ></input_with_label>

        <input_with_label
            ref="mfa_pw_reset_confirmation_code_box"
            class="login_input"
            label="Confirmation Code"
            @emit_state="catch_pw_reset_confirmation_code"
        ></input_with_label>

        <basic_text_button
            class="padding_lr_6 padding_tb_6  margin_t_40 margin_b_40"
            :hover_color=colors.cyanic_blue
            :hover_text_color=colors.white
            :button_state="true"
            :button_color="colors.new_orange_main"
            :text_color=colors.white
            :button_color_not_ready="colors.eli_gray"
            :button_not_ready_text_color="colors.pennie_gray"
            button_height="52px"
            button_width="150px"
            border_rad_pct="0"
            text="VERIFY"
            text_size="14px"
            :bold="true"
            event_name="verify_button_clicked"
            @verify_button_clicked="confirm_pw_reset"
            v-on:keyup.enter="confirm_pw_reset"
            tabindex="0"
        ></basic_text_button>

      </div>
    </div>
  </section>
  <outside_footer class="footer_c">
  </outside_footer>


</template>

<script>
import axios from "axios";
import {Database} from '@/client_db';
import {
  be_main_address,
  add_class_to_body,
  validate_confirmation_code,
  validate_basic_name,
  remove_class_to_body, route_and_log,
} from "@/library";
import store from "@/store";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import basic_text_button from "@/components/parts/basic_text_button";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import input_with_label from "@/components/parts/input_with_label";
import {
  colors
} from "@/library";

export default {
  components: {
    notification_popup,
    basic_text_button,
    error_popup_queue,
    outside_banner_std,
    outside_footer,
    input_with_label

  },
  data() {
    return {
      colors: colors,
      name: '',
      new_password: '',
      error_out: false,
      code: '',
      next_route: '',
      mobile_mode: true
    }
  },
  created() {
    this.db = new Database();
    store.commit('create_initial_defaults')
  },
  mounted() {
    add_class_to_body("bg_white")
  },
  unmounted() {
    remove_class_to_body('bg_white')
  },
  methods: {
    tester() {
      // console.log("QR CODE WAS CLICKED")
    },


    catch_pw_reset_username(username) {
      let name = username.trim().toLowerCase()
      // validate
      if (validate_basic_name(name)) {
        this.name = name
      } else {
        let m = "The user name you entered is not valid."
        this.error_out = true
        store.commit('error_enqueue', m)
      }
    },
    isLessThanEightCharacters(str) {
        return str.length < 8;
    },
    isSixDigitNumber(str) {
      return /^\d{6}$/.test(str);
    },
    catch_pw_reset_new_password(new_password) {
      let pw = new_password.trim()
      if (this.isSixDigitNumber(new_password)) {
        let m = "Did you put your Confirmation code in the password box?"
        this.error_out = true
        store.commit('error_enqueue', m)
      } else if(this.isLessThanEightCharacters(new_password)) {
            let m = "Passwords must be at least 8 characters"
            this.error_out = true
            store.commit('error_enqueue', m)
      } else{
        this.new_password = pw
      }
    },

    catch_pw_reset_confirmation_code(confirmation_code) {
      let code = confirmation_code.trim()
      // validate
      if (validate_confirmation_code(code)) {
        this.code = code
      } else {
        let m = "The confirmation code you entered is not valid. The proper format is 123456."
        this.error_out = true
        store.commit('error_enqueue', m)
      }
    },
    simple_route_prep(r, analytic_log = null) {
      console.log("in simple route prep")
      route_and_log(this.$router, r, analytic_log)
    },
    async confirm_pw_reset() {
      console.log("Getting ready to verify new code...")
      // console.log(`Current password from state is: ${this.password}`)

      // let the_router = this.$router

      this.$refs.pw_reset_username_box.emit_state()
      this.$refs.pw_reset_new_password_box.emit_state()
      this.$refs.mfa_pw_reset_confirmation_code_box.emit_state()
      if (this.error_out) {
        this.error_out = false
        return null
      }

      // let error_out
      // console.log("Getting ready to make axios call.")
      let target = be_main_address('main_api') + "set_new_pw"
      await axios.post(target, {
        name: this.name,
        code: this.code,
        password: this.new_password
      }).catch(function (error) {
        console.log("in the catch")
        console.log(error)
        if (error.response) {
          // console.log("Something went wrong with your login.")
          store.commit('error_enqueue', "Bad verification. Try again.")
          console.log(error)
          // error_out = true
          return null
        } else if (error.request) {
          let online = window.navigator.onLine;
          let message
          if (online) {
            message = "There are problems with your network connection, or Tiny Magic Hat may be down."
          } else {
            message = "You appear to have lost your network connection."
          }
          store.commit('error_enqueue', message)
          // error_out = true
          return null
        } else {
          // console.log("in the error catch all")
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
          store.commit('error_enqueue', "Unknown error on password change verification. Please report this to customer service.")
          // error_out = true
          return null
        }
      }).then((response) => {
            // console.log("Got back verification response of")
            // console.log(response.data)

            if (response.data.error === false) {
              console.log("Got good response to password change.");
              store.commit('notification_enqueue', response.data['message'])
              this.$router.push('/login');
              // this.$store.commit('save_name', this.name)
              // this.$store.commit('save_email', this.email)
              // this.$store.commit('save_phone', this.phone_number)
              // this.name = ''
              // this.phone_number = ''
              // this.password = ''
              // this.$router.push('/mfa_register_confirm');
            } else {
              console.log("Something went wrong with register method.")
              console.log(response.data)
              store.commit('error_enqueue', response.data['message'])
            }
          }
      )

    },


  },
  computed: {
    show_qr_code_backer() {
      return this.show_qr_code_backer_x
    },
  }
}
</script>

<style scoped>
@import '../../common.css';

.form_unit {
  /*height: 30px;*/
}

/**/

.main_grid {
  display: grid;
  width: 100vw;
  height: 100vh;
  max-width: 700px;
  grid-template-rows: 1fr 2fr 3fr 3fr 3fr;
  grid-template-areas: "top"
                         "logo"
                         "form"
                         "links"
                         "bottom";
}

.main_column {
  max-width: 75%;
  margin-bottom: 80px;
}

header {
  grid-area: top;
  display: flex;
  justify-content: center;
}

figure {
  margin: 0;
}


form label {
  /*font-size: 5.5vw;*/
  /*font-family: 'Lobster', cursive;*/
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  color: var(--black);
}


form label[for=user-name] {
  margin-top: 2vh;
}

form input {
  /*padding-left: 2vw;*/
  margin-bottom: 2.25vh;
  font-size: 6vw;
  padding-left: 17px;;
  /*text-align: center;*/
  font-family: 'Raleway', sans-serif;
  border-radius: 2em;
  height: 22px;
  width: 50vw;
}

@media (min-width: 100px) {
  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    margin-left: 1px;
    margin-right: 1px;
    text-decoration: none;
  }

  .login_input {
    width: 85vw;
    max-width: 400px;
  }

  .login {
    margin-top: 50px;
    margin-bottom: 25px;
  }

  #links_row {
    width: 98vw;
    max-width: 400px;
    margin-bottom: 40px;
    color: var(--primary-purple);
  }
}

@media (min-width: 500px) {


  form label {
    align-self: center;
    margin-bottom: .75vh;
    font-size: 25px
  }

  form input {
    margin-bottom: 2.25vh;
    align-self: center;
    font-size: 18px
  }

  .form-elements {
    grid-area: form;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 40vw;
    max-width: 700px;
    margin: auto;
    padding-top: 2vh;
    padding-bottom: 2vh;
  }


  form button {
    display: inline-block;
    padding: 0.3em 1.2em;
    margin: 1vh 0.1em 0.1em 0;
    border: 0.16em solid rgba(255, 255, 255, 0);
    border-radius: 2em;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    /*color: var(--light-text);*/
    color: yellow;

    /*font-family:'Lobster', cursive;*/
    /*font-weight:300;*/
    /*color: var(--light-text);*/
    background-color: var(--new-primary-purple);
    text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
    text-align: center;
    transition: all 0.2s;
    font-size: 20px;
  }

  .the_footer {
    grid-area: bottom;
    display: flex;
    justify-content: center;
    padding-left: 7vw;
  }

  .spacer {
    margin-left: 3vw;
    margin-right: 3vw;
  }

  #form_title {
    font-size: 35px;
  }

}

@media (min-width: 800px) {
  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    margin-left: 1px;
    margin-right: 1px;
    text-decoration: none;

  }
}


</style>