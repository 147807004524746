<template>
  <section class="screen_block bg_white margin_tb_20">
    <div v-for="(prospect, index) of prospects" :key="`item-${index}`">
      <referral_handle
          :prospect_data=prospect
          @retire_prospect_click="prospect_retire_click"
          @refer_prospect_click="refer_prospect_to_agency"
      >
      </referral_handle>
    </div>

  </section>

</template>

<script>
/* eslint-disable */
import referral_handle from "@/components/Vortex/admin_interface/referral_handle.vue";
import {be_main_address, parse_internal_json} from "@/library";
import store from "@/store";
import axios from "axios";

export default {
  name: "admin_main",
  props: ["prospects"],
  components: {
    referral_handle
  },
  methods: {
    send_referral_to_backend(agency, prospect_id){
      let target_url = be_main_address('vortex') + 'make_hch_referral'
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        subscriber: null,
        agency: agency,
        prospect_id: prospect_id,
      }

      axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" || response.status === 200) {
              let message = parse_internal_json(response.data)['payload']['content']['message']
              if (response.data['error'] === true) {
                store.commit('error_enqueue', message)
              } else {
                this.non_subscribing_pdas_ = response.data
                store.commit('notification_enqueue', message)
              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })
    },
    refer_prospect_to_agency(agency_and_prospect_id){
      console.log("refer_prospect_click received at referral widget level.");
      let agency
      let prospect_id
      agency = agency_and_prospect_id[0]
      prospect_id = agency_and_prospect_id[1]
      console.log(agency, prospect_id);
      this.send_referral_to_backend(agency, prospect_id);
    },
    prospect_retire_click(prospect_id) {
      console.log("prospect_retire_click");
      console.log(prospect_id);
      let endpoint = 'retire_prospect'

      let target_url = be_main_address('vortex') + endpoint

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        prospect_uid: prospect_id,
        subscriber: null,
      }

      // console.log(data1)

      console.log("Calling backend to retire vortex prospect.")

      axios.post(target_url, data1)
          .then((response) => {
            console.log("Just got a response from vortex.")
            console.log(response)
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              } else {
                // let message = parse_internal_json(response.data)['data']['message']
                console.log(typeof response.data.data)
                console.log(response.data.message)
                console.log("OK here is what came back out the other side.")
                store.commit('notification_enqueue', response.data.message)

                // this.click_values = response.data.data
                // if(retired){
                //   this.retired_discount_values = this.processAgencyVortexData(response.data.data)
                //   console.log(this.retired_discount_values)
                // }else{
                //   this.discount_values = this.processAgencyVortexData(response.data.data)
                //   console.log(this.discount_values)
                // }

              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })
       this.$emit('page_change',true)
    },


  }
}
</script>

<style scoped>

</style>