<template>
  <div class="flex_start_column margin_tb_10 margin_lr_20 referral_box">
    <div class="agency_name"><strong>{{ agency }}</strong></div>
    <div>{{ formatdate(referral_timestamp) }}</div>
  </div>
</template>

<script>
import {formatTimestamp} from "@/dates_library"
/* eslint-disable */
export default {
  name: "Referral_Given",
  props: ['agency', 'referral_timestamp'],
  methods: {
    formatdate(datestr){
      return formatTimestamp(datestr, 15);
    },
  },
}
</script>


<style scoped>
@import '../../../common.css';

@media (min-width: 100px) {

  .referral_box {
    background: #00c7ff;
    padding: 10px 10px;
    font-family: 'Raleway', sans-serif;
  }

  .agency_name {
    margin-bottom: 5px;
  }


  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
}

@media (min-width: 800px) {

  h1 {

  }

  h2 {

  }

  h3 {

  }

}

@media (min-width: 1200px) {
  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 2000px) {
}

h1 {

}

h2 {

}

h3 {

}
</style>