<template>
  <module_main_page
      module_download_name="099ef69c-e042-420e-8deb-c5da3507d9d8"
      module_logo_name="sd_vascular_problems.svg"
      :module_desc_text=module_text
      module_headline="Common Vascular Conditions"
  ></module_main_page>
</template>

<script>
import module_main_page from "@/components/SpeedyDelivery/module_pages/module_main_page";

export default {
  name: "vascular_problems",
  data() {
    return {
      module_text: "Some prevalent vascular conditions clients may experience include hypertension (high blood pressure), peripheral artery disease (PAD), and deep vein thrombosis (DVT). Caregivers need to monitor blood pressure regularly for clients with hypertension, as uncontrolled high blood pressure can lead to serious health complications. For individuals with PAD, caregivers should understand the symptoms, such as leg pain or cramping during physical activity, and encourage regular exercise and proper foot care to prevent complications like ulcers. DVT is characterized by blood clots in deep veins, and caregivers should be vigilant about signs such as swelling and pain in the legs. Promoting mobility and adherence to prescribed blood-thinning medications is crucial for DVT management.",
    }

  },
  components: {
    module_main_page
  },
}
</script>

<style scoped>

</style>



