<template>
  <div class="core_c ">
    <error_popup_queue></error_popup_queue>
    <notification_popup></notification_popup>
    <landing_banner></landing_banner>
    <!--    <meta name="og:image" property="og:image" content="https://tinymagichat.com/img/coffee_couch.31f6f1ab.jpg"/>-->
    <!--     og_image="http://localhost:8089/img/old_hands.de2922bb.jpg"-->
    <headers
        title="Find Home Care"
        meta_description="Tiny Magic Hat makes it easy to find a quality home care agency in your neighborhood.."
        og_title="Find Home Care"
        og_url="https://tinymagichat.com/spyglass_notifications"
        og_description="Tiny Magic Hat takes the work out of finding a quality home care agency in your neighborhood."
        og_image="http://tinymagichat.com/img/coffee_couch.31f6f1ab.jpg"
    ></headers>

    <div class="flex_centered_row ">
      <div class="flex_centered_start_column">


        <section class="top_section wid_100vw  ">
          <div class="top_section_content ">

            <div v-if="!mobile_mode" class="flex_centered_column">
              <div>
                <img class="padding_tb_20" id="cg_topper_img"
                     src="../../../assets/istock/easy_button.jpg"
                     alt="A blue easy button.">
              </div>
            </div>

            <div class="flex_centered_column right_side">
              <h1 class="text_align_center">Find Home Care in {{ market }} and get a <u>discount</u>.</h1>
              <p class="text_align_center top_text">
                <!--                Finding the right home care agency for your needs doesn't have to been complicated or time consuming.-->
                Find home care agencies in {{ market }} and their current hourly rates in just a few minutes. </p>
              <!--              <div class="flex_centered_row flex_wrap neighborhood_list margin_t_20">-->

              <!--                <p class="text_align_center top_text">Tiny Magic Hat member agencies in {{ market }} represent the-->
              <!--                  top agencies in their local market. They are frequently part of national franchise operations with-->
              <!--                  years of industry experience and are ready to deliver the same excellent care to your or-->
              <!--                  your loved one.</p>-->
              <!--              </div>-->

            </div>
          </div>
        </section>

        <div class="share_row_wrap flex_centered_row">
          <div class="flex_fully_centered_space_around_row share_row">
            <ShareNetwork
                network="facebook"
                url="https://tinymagichat.com/markets"
                title="Find local home care easily."
                description="Find local agencies and their current hourly rates in just a few minutes before talking with anyone on the phone."
                quote=""
                hashtags=""
            >
              <img class="padding_tb_20 fb_share"
                   src="../../../assets/facebook-share-button-icon.svg"
                   alt="Facebook share icon">
            </ShareNetwork>

            <ShareNetwork
                network="sms"
                url="https://tinymagichat.com/markets"
                title="Find local home care easily."
                description="Find local agencies and their current hourly rates in just a few minutes before talking with anyone on the phone."
                quote=""
                hashtags=""
            >
              <img class="padding_tb_20 mail_share"
                   src="../../../assets/sms_icon.svg"
                   alt="Text Message share icon">
            </ShareNetwork>

            <ShareNetwork
                network="email"
                url="https://tinymagichat.com/markets"
                title="Find local home care easily."
                description="Find local agencies and their current hourly rates in just a few minutes before talking with anyone on the phone."
                quote=""
                hashtags=""
            >
              <img class="padding_tb_20 mail_share"
                   src="../../../assets/email_icon.svg"
                   alt="Email share icon">
            </ShareNetwork>

          </div>
        </div>

<!--        <section class="flex_centered_row  top_explainer">-->
<!--          <div class="flex_centered_column">-->
<!--            <h2 class="text_align_center">You're Almost There</h2>-->
<!--            <p>Review agencies and manually register your discount or let the <strong>Home Care Helper</strong> handle everything for you.</p>-->

<!--          </div>-->
<!--        </section>-->

        <section class="flex_centered_row  top_explainer home_care_helper bg_lauren_gray">
          <div class="flex_centered_column">
            <h2 class="text_align_center">Meet the Home Care Helper</h2>
            <p>If you like, call our Home Care Helper and share your care needs
              in one simple step. We'll relay your information to our agencies and register your home care discount for you.
              </p>

            <call_us_box
                class="click_to_call_box"
                :click_to_call_mode=mobile_mode
                :phone_number=this.tmh_home_care_helper_phone_number
                text="Call Home Care Helper"
                @call_clicked=call_interested
            ></call_us_box>

            <p>Or, if you prefer, scroll down to register your discount manually with an agency and then contact them directly yourself. </p>

          </div>

        </section>

        <section class="cards flex_wrap">
          <home_card2
              class=" margin_t_20 first_card"
              base_video_height="180"
              base_video_width="320"
              video_id=""
              button_text="Learn More"
              @main_btn_clicked="do_nothing"
              button_color=""
              icon="personal_info_black.svg"
              desc="Job notifications"
              :hide_button="true"
              :headline_color="colors.primary_purple"
              button_text_color=""
              :hover_color="colors.cyanic_blue"
              :hover_text_color="colors.white"
              :icon_color="colors.white"
              :icon_bg_color="colors.new_orange_main_light"
          >
            <template v-slot:headline>
              <div>Provide Basic Details</div>
            </template>
            <template v-slot:body>
              <p class="get_notified_text">Tell us a little bit about yourself and the person needing care.
              </p>
            </template>
          </home_card2>
          <home_card2
              class=" first_card "
              base_video_height="180"
              base_video_width="320"
              video_id=""
              button_text="Learn More"
              @main_btn_clicked="do_nothing"
              button_color=""
              icon="heart_home.svg"
              desc="Find a job Easy"
              :hide_button="true"
              :headline_color="colors.primary_purple"
              button_text_color=""
              :hover_color="colors.cyanic_blue"
              :hover_text_color="colors.white"
              :icon_color="colors.white"
              :icon_bg_color="colors.new_gold"
          >
            <template v-slot:headline>
              <div>Browse Agencies and Prices</div>
            </template>
            <template v-slot:body>
              <p class="talking">See a selection of the best agencies in {{ market }}, compare prices, and if you're
                ready, talk with an agency
                representative.
              </p>
              <!--              <br>-->
              <!--              <p><strong>*You do need to register your free account first.</strong></p>-->
            </template>
          </home_card2>

          <home_card2
              class=" first_card "
              base_video_height="180"
              base_video_width="320"
              video_id=""
              button_text="Learn More"
              @main_btn_clicked="do_nothing"
              button_color=""
              icon="icon_money_black.svg"
              desc="Find a job Easy"
              :hide_button="true"
              :headline_color="colors.primary_purple"
              button_text_color=""
              :hover_color="colors.cyanic_blue"
              :hover_text_color="colors.white"
              :icon_color="colors.white"
              :icon_bg_color="colors.new_gold"
          >
            <template v-slot:headline>
              <div>Register Your Discount</div>
            </template>
            <template v-slot:body>
              <p class="talking">Many agencies provide a substantial discount for new client sign-ups. Register and
                claim yours!
              </p>
            </template>
          </home_card2>
        </section>

        <section>
          <div v-if="!show_market_agencies" class="flex_fully_centered_column margin_t_40 main_column">
            <h3>Provide Basic Details</h3>
            <input_with_label
                ref="searcher_name_box"
                :focus="true"
                class="login_input"
                label="Your Name"
                @emit_state="save_searcher_input($event, 'searcher_name')"
            ></input_with_label>
            <input_with_label
                ref="searcher_phone_box"
                :focus="false"
                class="login_input"
                label="Your Phone Number"
                @emit_state="save_searcher_input($event, 'searcher_phone')"
            ></input_with_label>
            <input_with_label
                ref="searcher_client_name_box"
                :focus="false"
                class="login_input"
                label="Name of Client"
                @emit_state="save_searcher_input($event, 'client_name')"
            ></input_with_label>
            <input_with_label
                ref="searcher_client_phone_box"
                :focus="false"
                class="login_input"
                label="Client Phone Number"
                @character_entered="look_for_good_phone"
                @emit_state="save_searcher_input($event, 'client_phone')"
            ></input_with_label>
            <basic_text_button
                class="padding_lr_6 padding_tb_6 bottom_button mailing_list_button"
                :hover_color=colors.cyanic_blue
                :hover_text_color=colors.white
                :button_state="form_complete"
                :button_color="colors.new_orange_main"
                :text_color=colors.white
                :button_color_not_ready="colors.eli_gray"
                :button_not_ready_text_color="colors.pennie_gray"
                button_height="52px"
                :button_width=button_wid
                border_rad_pct="0"
                text="See Pricing"
                text_size="14px"
                :bold="true"
                event_name="reveal_agency_data"
                @reveal_agency_data=reveal_agency_market_data
            ></basic_text_button>
          </div>
        </section>

        <section v-show="show_market_agencies" class="agencies_section flex_centered_column">
          <h3 class="wid_85 text_align_center">Myrtle Beach Area Home Care Pricing data as of {{ current_week }}</h3>
          <div v-if="market_data" class="flex_centered_row flex_wrap">
            <agency_ad_widget
                v-for="(value, key) in market_data" :key="`item-${key}`"
                :data=value
            ></agency_ad_widget>
          </div>
        </section>
      </div>
    </div>

  </div>
  <outside_footer_landing class="footer_c"></outside_footer_landing>

</template>
<script>
/* eslint-disable */
import error_popup_queue from "@/components/basics/error_popup_queue.vue";
import notification_popup from "@/components/basics/notification_popup.vue";
import outside_banner_std from "@/components/parts/outside_banner_std.vue";
import outside_footer from "@/components/parts/outside_footer.vue";
import basic_text_button from "@/components/parts/basic_text_button.vue";
import video_modal from "@/components/parts/video_modal.vue";
import input_with_label from "@/components/parts/input_with_label.vue";
import carousel_container from "@/components/parts/my_carousel/carousel_container.vue";
import home_card2 from "@/components/parts/home_card2.vue";
import landing_banner from "@/components/parts/landing_banner.vue";
import outside_footer_landing from "@/components/parts/outside_footer_landing.vue";
import indicator_label from "@/components/parts/indicator_label.vue";
import basic_icon_button from "@/components/parts/basic_icon_button.vue";
import headers from "@/components/parts/headers.vue";
import store from "@/store";
import {
  get_uuid,
  round,
  colors,
  handle_notification_subscription,
  showLocalNotification,
  scroll_to_top,
  mailing_list_signup,
  be_main_address,
  parse_internal_json,
  get_ip_from_header,
  detect_connection_speed,
  message_sw, scroll_to_element, dual_route, validate_email, validate_phonenumber, stripNonAlphanumeric
} from "@/library";
import {Database} from "@/client_db";
import axios from "axios";
import drop_down_select from "@/components/parts/drop_down_select.vue";
import Agency_ad_widget from "@/components/Vortex/JointAdPage/agency_ad_widget.vue";
import call_us_box from "@/components/SpyGlass/call_us_box.vue";

export default {
  name: "market_ad_page",
  created() {
    this.db = new Database();
    store.commit('create_initial_defaults')
  },
  async mounted() {
    this.id_ = get_uuid()
    this.scroll_to_top()

    let url = localStorage.getItem('dev_latest_route')
    console.log(`Found these parts to the route`)
    const parts = url.split('/').filter(part => part !== '');
    console.log(parts)
    this.market_ = this.replacePercent20WithSpace(parts[parts.length - 1]);
    this.state_ = parts[parts.length - 2];
    console.log(`State and Market determined from url are ${this.state} and ${this.market}`)
    let res
    if (localStorage.getItem('vortex_visitor_data')) {
      res = JSON.parse(localStorage.getItem('vortex_visitor_data'))
    } else {
      let uuid = get_uuid()
      res = {
        'vortex_visitor': uuid,
        'market': this.market,
        'state': this.state
      }
      let res_json = JSON.stringify(res)
      localStorage.setItem('vortex_visitor_data', res_json)
    }
    await this.send_click_stat(res)

    await this.load_market_data()
    this.run_window_width_adjustments(this.windowWidth)
    let ip_string = false

    await detect_connection_speed()
  },


  props: [],
  components: {
    call_us_box,
    Agency_ad_widget,
    drop_down_select,
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    basic_text_button,
    input_with_label,
    video_modal,
    carousel_container,
    home_card2,
    landing_banner,
    outside_footer_landing,
    indicator_label,
    basic_icon_button,
    headers

  },
  data() {
    return {
      id_: "",
      tmh_home_care_helper_phone_number: "9843164808",
      colors: colors,
      mobile_mode_x: true,
      notification_soft_perm_: false,
      state_market: ['SC:Myrtle Beach'],
      state_market_chosen_: "",
      local_neighborhoods: [],
      selected_neighborhoods: [],
      bad_email: true,
      state_: "",
      market_: "",
      name: '',
      button_wid_: 250,
      show_hamburger_menu_: false,
      desktop_cutoff: 1000,
      input: {
        email: "",
        searcher_name: "",
        searcher_phone: "",
        client_name: "",
        client_phone: "",
      },
      market_data_: {},
      current_week_: "",
      show_market_agencies_: false
    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    call_interested() {
      // console.log("in caller")
      let num = this.tmh_home_care_helper_phone_number
      let full_num = 'tel:' + num
      window.open(full_num);
    },

    look_for_good_phone(e) {

      e = e.replace(/\D/g, '');
      console.log(`Stripped phone number is ${e}`)
      if (validate_phonenumber(e)) {
        console.log(`${e} valid phone number found`)
        this.$refs['searcher_client_phone_box'].emit_state()
        this.$refs['searcher_phone_box'].emit_state()
        this.$refs['searcher_name_box'].emit_state()
        this.$refs['searcher_client_name_box'].emit_state()
        console.log(`${this.form_complete}`)
        // this.input['client_phone'] = e
      } else {
        console.log(`${e} is not a valid phone number`)
        //this is not yet a valid phone number
      }
    },

    save_searcher_input(e, command) {
      console.log("In save searcher input")
      console.log(e)
      console.log(command)

      if (command === 'searcher_name') {
        console.log("got searcher_name")

        this.input[command] = e.trim()
      } else if (command === 'searcher_phone') {
        console.log("got searcher_phone")
        e = e.replace(/\D/g, '');
        console.log(`Stripped phone number is ${e}`)
        if (validate_phonenumber(e)) {
          this.input[command] = e
        } else {
          store.commit('error_enqueue', "The phone number you entered is invalid.")
        }
      } else if (command === 'client_name') {
        console.log("got client_name")
        this.input[command] = e.trim()
      } else if (command === 'client_phone') {
        console.log("got client_phone")
        e = e.replace(/\D/g, '');
        if (validate_phonenumber(e)) {
          this.input[command] = e
        } else {
          store.commit('error_enqueue', "The phone number you entered is invalid.")
        }
      } else {
        console.log("got an unexpected value")
      }
      console.log("Current searcher input is...")
      console.log(this.input)
    },

    clear_form() {
      this.$refs['searcher_client_phone_box'].reset_state()
      this.$refs['searcher_phone_box'].reset_state()
      this.$refs['searcher_name_box'].reset_state()
      this.$refs['searcher_client_name_box'].reset_state()
    },

    save_preliminary_input_to_vuex() {
      // store.commit('set_current_vortex_agency', this.input['searcher_name'])
      console.log("Market Data")
      console.log(this.market_data)
      console.log("this.data...")
      console.log(this.input)

      let current_data = {
        'searcher_name': this.input['searcher_name'],
        'searcher_phone': this.input['searcher_phone'],
        'client_name': this.input['client_name'],
        'client_phone': this.input['client_phone'],
        'agency': "",
        'discount': ""
      }

      // store.commit('set_current_vortex_searcher_name', this.input['searcher_name'])
      // store.commit('set_current_vortex_searcher_phone', this.input['searcher_phone'])
      // store.commit('set_current_vortex_client_name', this.input['client_name'])
      // store.commit('set_current_vortex_client_phone', this.input['client_phone'])
      store.commit("set_current_vortex_collected_data", current_data)
    },

    reveal_agency_market_data() {
      console.log("Revealing the agency market data...")
      console.log(this.input)
      console.log("is form complete accourding to computed?")
      console.log(this.form_complete)

      // if(this.input['searcher_name'] && this.input['searcher_phone'] && this.input['client_name'] && this.input['client_phone']){
      if (this.form_complete) {
        console.log("Client data has been populated.")
        this.show_market_agencies_ = true
        this.save_preliminary_input_to_vuex()
        this.clear_form()
      } else {
        store.commit('error_enqueue', 'You must first complete your basic details before browsing pricing data.')
      }
    },
    async load_market_data() {
      //Pull market data for each agency.
      console.log(`Pulling the market data: ${this.market}, ${this.state}`)
      let target_url = be_main_address('vortex') + 'load_market_data'
      console.log(`Target url: ${target_url}`)
      let ip_string = await get_ip_from_header()
      console.log("Here is the ip I found...")
      console.log(ip_string)

      let data1 = {
        market: this.market,
        state: this.state,
        ip: ip_string
      }

      console.log(`Calling for market data for ${this.market} in ${this.state}`)

      axios.post(target_url, data1)
          .then((response) => {
            console.log("Just got a response on market data for vortex")
            console.log(response)
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              } else {
                console.log("Here is the returned market data")
                console.log(response.data)
                this.current_week_ = response.data[0]
                this.market_data_ = response.data[1]
              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })

    },


    send_click_stat(res) {
      // Pull market data for each agency.
      console.log(`Sending click data for ${this.market}`);
      let target_url = be_main_address('vortex') + 'record_clicks_market_page';

      get_ip_from_header()
          .then((ip_string) => {
            console.log("Here is the IP I found...");
            console.log(ip_string);

            if (ip_string === undefined) {
              ip_string = 'unknown'
            }

            res['ip'] = ip_string
            localStorage.setItem('vortex_visitor_data', JSON.stringify(res))


            let data1 = {
              market: this.market,
              state: this.state,
              ip: ip_string,
              vortex_id: localStorage.getItem('vortex_visitor')
            };

            console.log(`Calling for market data for ${this.market} in ${this.state}`);
            return axios.post(target_url, data1);
          })
          .then((response) => {
            console.log("Just got a response on register discount page visit data submission from server.");
            console.log(response);
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message'];
                store.commit('error_enqueue', message);
              } else {
                console.log("Click data has been posted");
                console.log(response.data);
              }
            } else {
              console.log("SERVER ERROR");
              console.log(response);
            }
          })
          .catch((error) => {
            console.log("Error occurred during click data submission:");
            console.log(error);
          });
    }
    ,

    splitString(input) {
      // Split the input string at the colon
      const parts = input.split(':');

      // Ensure there are exactly two parts after the split
      if (parts.length !== 2) {
        throw new Error('Input string must contain exactly one colon.');
      }

      // Return the two parts as separate strings
      return parts;
    },

    replacePercent20WithSpace(input) {
      // Replace all occurrences of "%20" with a space
      return input.replace(/%20/g, ' ');
    },

    async join_mailing_list_clicked() {
      console.log("You joined the mailing list")
      let res = await mailing_list_signup(this, this.input.email, 'caregiver').then(function (res) {
        if (res[0] === "OK") {
          console.log("Email mailing list sign up was successful.")
          let context = res[1]
          context.input.email = ""
          context.$refs.mailing_list_email.reset_state()
          return true
        } else {
          console.log("Email mailing list sign up did not go as planned.")
          return false
        }
      })
    },

    get_market_banner_blurb() {
      //   Call to backend with state and market.
      let blurb = "Blurb"
      return blurb

    },

    catch_email(content) {
      // if (!validate_email(content)) {
      //   console.log("this email is bad")
      //   this.bad_email = true
      // }
      this.input.email = content.trim().slice(0, 100)
      // this.bad_email = false

    },
    async freetime_landing() {
      // localStorage.setItem('from_route','spyglass')
      // console.log("freetime_landing clicked")
      // let analytic = '01:01:13:208'
      // console.log("running dual route wrapper")
      store.commit('activate_section_for_caregivers', 'freetime')
      await this.dual_route_wrapper('for-caregivers')
    },
    dual_route_wrapper(route, inner = null) {
      // this.hamburger_menu_false()
      let current = localStorage.getItem('dev_latest_route')
      current = current.substring(1)
      if (current === route) {
        scroll_to_element(inner)
        return null
      }
      dual_route(this.$router, route, inner)
    },


    async static_delete() {

      store.commit('set_update_available', 'pending')
      // localStorage.setItem('update_available', 'pending')
      this.hamburger_menu_false()
      try {
        message_sw("DELETE_SW_CACHE", "Service Worker deleting all SW caches.")
        setTimeout(function () {
          message_sw("PRECACHE_ASSETS", "Calling sw to precache.")
          location.reload()
        }, 5000);
      } catch (error) {
        console.log(error)
      }

    },

    check_if_neighborhood_selected(item) {
      return this.sel_neighborhoods.includes(item);
    },

    select_neighborhood(item, index) {
      console.log(`Just picked ${item} with index of ${index}`)
      if (this.selected_neighborhoods.includes(item)) {
        //Remove the item, change the styling
        this.selected_neighborhoods = this.selected_neighborhoods.filter(e => e !== item);
        // let neighborhood = document.getElementById(`n-${index}`)
        // neighborhood.style.background = colors.new_orange_main

      } else {
        //Remove the item, change the styling
        this.selected_neighborhoods.push(item)
        // let neighborhood = document.getElementById(`n-${index}`)
        // neighborhood.style.background = colors.red
      }


      // this.$refs.neighborhood_[index].button_color = colors.whitesmoke

    },
    get_uuid() {
      return this.get_uuid()
    },
    async get_local_neighborhoods(market = 'Myrtle Beach') {
      console.log("STARTING TO GET THE NEIGHBORHOODS...........................")
      let target = be_main_address('geography') + "neighborhood_listing_open"
      // console.log("create new user address")
      console.log(target)

      // console.log(`Phone Number: ${this.phone_number}`)
      await axios.post(target, {
        filter: market,
      }).catch(function (error) {
        console.log("in the catch")
        console.log(error)
        if (error.response) {
          // console.log("Something went wrong with your login.")
          store.commit('error_enqueue', "Something went wrong at the server. Please reload the page.")
          console.log(error)
          return null
        } else if (error.request) {
          let online = window.navigator.onLine;
          let message
          if (online) {
            message = "There are problems with your network connection, or Tiny Magic Hat may be down."
          } else {
            message = "You appear to have lost your network connection."
          }
          store.commit('error_enqueue', message)
          return null
        } else {
          // console.log("in the error catch all")
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
          return null
        }
      }).then((response) => {
            console.log("Got good response from geography.");
            console.log(response.data);
            // store.commit('notification_enqueue', response.data['message'])
            if (response.data.error === false) {

              console.log("Here are the neighborhoods.")
              console.log(response.data.data)
              this.local_neighborhoods = response.data.data
              // this.local_neighborhoods.push("Tester1 aoeu")
              // this.local_neighborhoods.push("Tester2 htee ")
              // this.local_neighborhoods.push("Tester3hnhn")
              // this.local_neighborhoods.push("Tester4 nthoeu hntoeu ")
              // this.local_neighborhoods.push("Tester5 theu")
              // this.local_neighborhoods.push("Tester6 thoa")
              // this.local_neighborhoods.push("Tester7")
              // this.local_neighborhoods.push("Tester8")
              // this.local_neighborhoods.push("Tester9 ahc ohce")
              // this.local_neighborhoods.push("Tester10 thec")
            } else {
              console.log("Something went wrong getting the neighborhood listing.")
              console.log(response.data)
            }
          }
      )
    },
    async accept_notifications() {

      if (this.selected_neighborhoods.length === 0) {
        store.commit('notification_enqueue', "You need to select at least one neighborhood for notifications.")
        return null
      }

      let market = 'Myrtle Beach'
      let state = 'SC'
      let country = 'US'
      // console.log('setting soft notifications_ to true in method')
      let subscription_info = await handle_notification_subscription(true)
      // console.log(`The subscription info is ${subscription_info}`)

      let values = {
        country: country,
        state: state,
        market: market,
        neighborhoods: this.selected_neighborhoods,
        subscription_info: subscription_info,
      }

      console.log('sending over the following dict.....')
      console.log(values)

      let res = await this.save_notification_subscription(values)

    },

    async save_notification_subscription(params) {
      let endpoint = be_main_address('main_api') + 'save_notifications_subscription'
      let data1 = {
        country: params['country'],
        market: params['market'],
        neighborhoods: params['neighborhoods'],
        state: params['state'],
        subscription_info: params['subscription_info']

      }
      await axios({
        method: 'post',
        url: endpoint,
        data: data1,
      }).then((response) => {
        console.log(response.data)
        // let message = parse_internal_json(response.data)['payload']['content']['message']
        if (response.data['error'] === true) {
          console.log("Got back an error from the server...")
          console.log(response.data)
          // let message = parse_internal_json(response.data)['payload']['content']['message']
          let message = parse_internal_json(response.data)['message']
          store.commit('error_enqueue', message)
        } else {
          // this.ready_to_save_ = false
          // store.commit('spyglass_agency_landing_set_ready_to_save', false)
          let message = parse_internal_json(response.data)['message']
          // let m = "That was easy! Now you will get a notification ever time a caregiving job opens up in your " +
          //     "selected neighborhoods."
          store.commit('notification_enqueue', message)
          this.selected_neighborhoods = []
          return true
        }
      }, (error) => {
        console.log('Error on save notification subscription.')
        console.log(error);
        store.commit('error_enqueue', "Something went wrong with your subscription. " +
            "Please try again.")
      });
    },

    async local_notification_test() {
      // const options = {
      //   body: 'Simple piece of body text.\nSecond line of body text 👍'
      // };
      // let body = 'Check out the posting @\nhttps://tinymagichat.com/spyglass'

      let body = 'Agency FreeTime Rate: $18.21/hr'
      await showLocalNotification("Caregiver job in Murrells Inlet just posted!", body)

    },
    async server_notification_test() {
      // const options = {
      //   body: 'Simple piece of body text.\nSecond line of body text 👍'
      // };
      // let body = 'Check out the posting @\nhttps://tinymagichat.com/spyglass'
      let title = 'Hello Wyatt.'
      let body = 'Hope all is well today.'
      let body_obj = {
        'title': title,
        'body': body
      }
      let body_json = JSON.stringify(body_obj)
      let target = be_main_address('main_api') + "push_demo"
      let data_obj = {
        url: "https://tinymagichat.com/contact",
        status: true
      }
      let data_json = JSON.stringify(data_obj)
      await axios.post(target, {
        neighborhood: "Murrells Inlet",
        body: body_json,
        data: data_json,
      }).catch(function (error) {
        console.log("in the catch")
        console.log(error)
        if (error.response) {
          // console.log("Something went wrong with your login.")
          store.commit('error_enqueue', "Bad Push Test Attempt.")
          console.log(error)
          return null
        } else if (error.request) {
          let online = window.navigator.onLine;
          let message
          if (online) {
            message = "There are problems with your network connection, or Tiny Magic Hat may be down."
          } else {
            message = "You appear to have lost your network connection."
          }
          store.commit('error_enqueue', message)
          return null
        } else {
          return null
        }
      }).then((response) => {
            console.log("Got good response to register.");
            console.log(response.data);
            store.commit('notification_enqueue', response.data['message'])
            if (response.data.error === false) {

            } else {
              console.log("Something went wrong with push test attempt.")
              console.log(response.data)
            }
          }
      )

    },
    tester() {
      // console.log("button was pressed.")
    },
    get_carousel_quotes() {
      // return  store.commit('get_carousel_quotes', 'for_agencies')
      return store.getters.get_home_carousel_quotes
    },
    video_modal_false() {
      this.video_modal.state = false
    },

    video_modal_true() {
      console.log("changing video modal to true")
      this.video_modal.state = true
    },
    scroll_to_top() {
      scroll_to_top()
    },
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      // if (new_w >= this.desktop_cutoff) {
      //   this.mobile_mode_x = false
      // } else {
      //   this.mobile_mode_x = true
      // }
      if (this.num_is_between_inclusive(100, 300, new_w)) {
        this.button_wid_ = 250
        this.mobile_mode_x = true
        // console.log(`Cutoff: ${this.desktop_cutoff}.  Width: ${new_w}. Mobile Mode: ${this.mobile_mode_x}`)
      }
      if (this.num_is_between_inclusive(301, 500, new_w)) {
        this.mobile_mode_x = true
        // console.log(`Cutoff: ${this.desktop_cutoff}.  Width: ${new_w}. Mobile Mode: ${this.mobile_mode_x}`)
      }
      if (this.num_is_between_inclusive(501, 700, new_w)) {
        this.mobile_mode_x = true
        // console.log(`Cutoff: ${this.desktop_cutoff}.  Width: ${new_w}. Mobile Mode: ${this.mobile_mode_x}`)
      }
      if (this.num_is_between_inclusive(701, 900, new_w)) {
        this.mobile_mode_x = true
        // console.log(`Cutoff: ${this.desktop_cutoff}.  Width: ${new_w}. Mobile Mode: ${this.mobile_mode_x}`)
      }
      if (this.num_is_between_inclusive(901, 999, new_w)) {
        this.mobile_mode_x = true
        // console.log(`Cutoff: ${this.desktop_cutoff}.  Width: ${new_w}. Mobile Mode: ${this.mobile_mode_x}`)
      }
      if (this.num_is_between_inclusive(1000, 9000, new_w)) {
        this.button_wid_ = 300
        this.mobile_mode_x = false
        // console.log(`Cutoff: ${this.desktop_cutoff}.  Width: ${new_w}. Mobile Mode: ${this.mobile_mode_x}`)
      }

    },
    num_is_between_inclusive(bottom, top, num) {
      return bottom <= num && top >= num;
    },
    // hamburger_menu_toggle() {
    //   this.show_hamburger_menu_ = !this.show_hamburger_menu_
    // },
    //
    // hamburger_menu_false() {
    //   this.show_hamburger_menu_ = false
    // },

    simple_route_prep(r) {
      this.hamburger_menu_false()
      // console.log("in simple route prep")
      let this_route = `/${r}`
      store.commit('save_latest_route', this_route)
      this.$router.push({path: this_route})
    },

  },
  computed: {
    id() {
      return this.id_
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    show_market_agencies() {
      return this.show_market_agencies_
    },
    market() {
      return this.market_
    },
    current_week() {
      return this.current_week_
    },
    market_data() {
      return this.market_data_
    },
    state() {
      return this.state_
    },
    form_complete() {
      return !!(this.input['searcher_name'] && this.input['searcher_phone'] && this.input['client_name'] && this.input['client_phone']);
    },
    // sel_neighborhoods() {
    //   return this.selected_neighborhoods
    // },
    //
    // notification_soft_perm() {
    //   return this.notification_soft_perm_
    // },
    // windowWidth() {
    //   return store.state.windowWidth;
    // },
    button_wid() {
      return `${this.button_wid_}px`
    },
    // modal_up() {
    //   return this.video_modal.state
    // },
    // show_hamburger_menu() {
    //   return this.show_hamburger_menu_
    // },
    mobile_mode() {
      return this.mobile_mode_x
    },

  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },

}
</script>
<style scoped>
@import '../../../common.css';


@media (min-width: 100px) {

  #cg_topper_img {
    width: 0px;
  }

  .share_row_wrap {
    width: 100vw;
    background: var(--lauren_gray);
    margin-bottom: 60px;
  }

  .share_row {
    width: 90vw;
    max-width: 450px;


  }

  .fb_share {
    width: 80px;
  }

  .mail_share {
    width: 40px
  }

  .mailing_list_text {
    max-width: 800px;
    line-height: 2rem;
    margin-bottom: 30px;
    margin-top: -30px;
  }

  .mailing_list_button {
    margin-top: 40px;
  }

  .learn_more_heading {
    /*margin-top: -50px;*/
    max-width: 500px;
    text-align: center;
  }

  .learn_more_text {
    text-align: center;
    max-width: 500px;
    margin-bottom: 20px;
    margin-top: -30px;
    line-height: 2rem;
  }

  h1 {
    font-size: 40px;
    color: white;
    width: 75%;
    margin-bottom: 30px;
  }

  h2 {
    font-size: 32px;
    color: var(--primary-purple);
    width: 75%;
    margin-bottom: 30px;
  }

  .top_explainer p {
    text-align: center;
  }

  .top_explainer {
    max-width: 850px;
    margin-top: 10px;
  }

   .home_care_helper {
    max-width: 850px;
    margin-top: 40px;
     padding: 40px;
  }
   .click_to_call_box{
     margin-top: 40px;
     margin-bottom: 40px;
     margin-left: 40px;
     margin-right: 40px;

   }

  .get_notified_text {
    text-align: center;
  }

  .talking {
    text-align: center;
  }

  .neighborhood_list {
    max-width: 90%;
  }

  .first_card {
    margin-top: -10px;
    margin-bottom: -50px;
  }

  .second_card {
    margin-top: -50px;
    margin-bottom: 50px;

  }

  h3 {
    font-size: 27px;
    padding-top: 50px;
    padding-bottom: 50px;
    /*padding-right: 20px;*/
    /*padding-left: 20px;*/
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    padding-top: 80px;
    padding-bottom: 0;
    max-width: 280px;
    /*margin-bottom: -20px;*/
  }

  p {
    font-size: 20px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .top_section {
    background: linear-gradient(180deg, var(--new-primary-purple-very-deep), var(--new-primary-purple-deep) 40.0%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;

  }

  .top_section_content {
    padding-bottom: 50px;
    padding-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .right_side h3 {
    color: white;
  }

  .right_side p {
    color: white;
  }

  .carousel {
    margin-top: 50px;
  }

  .bottom_button {
    margin-bottom: 50px;
  }

  .cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

}


@media (min-width: 360px) {

}

@media (min-width: 400px) {
  .below_carousel h3 {
    padding-top: 80px;
    padding-bottom: 0;
    margin-bottom: 100px;
    font-size: 30px;
  }
}

@media (min-width: 500px) {

  /*.top_explainer {*/
  /*  margin-top: 80px;*/
  /*}*/
  h3 {
    font-size: 37px;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 20px;
    padding-left: 20px;
  }

  p {
    font-size: 25px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .carousel {
    margin-top: 100px;
  }

  .second_card {
    margin-top: unset;
  }

}


@media (min-width: 750px) {

  .top_text {
    max-width: 85%;
  }

}

@media (min-width: 1000px) {

  .learn_more {
    margin-top: unset;
    /*max-width: ;*/
  }

  .top_section_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    max-width: 90%;

    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
  }

  .first_card {
    margin-top: 0;
    margin-bottom: 0;
  }

  .second_card h3 {
    max-width: 250px;
  }

  .cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  #cg_topper_img {
    width: 450px;
  }

  .bottom_button {
    margin-bottom: 100px;
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    padding-top: 80px;
    padding-bottom: 0;
    max-width: 350px;
    font-size: 35px;
    /*margin-bottom: -20px;*/
  }


}

@media (min-width: 1200px) {


  .right_side {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .right_side h3 {
    width: 390px;
    text-align: center;
    font-size: 42px;
  }

  #cg_topper_img {
    width: 700px;
  }

  .top_section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    margin-top: 100px;
    font-size: 38px;
  }

  .cards {
    margin-top: 0px;
  }

  .top_text {
    max-width: 800px;
    margin-left: 20px;
    line-height: 1.4;

  }


}

@media (min-width: 2000px) {

  .carousel {
    margin-top: 150px;
  }

  .fb_share {
    width: 100px;
  }

  .mail_share {
    width: 50px
  }


  .top_section_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 1450px;

    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
  }


}

</style>