<template>
  <div class="core_c">
    <spinner
        :spinner_up="spinner_up"
    ></spinner>
    <error_popup_queue></error_popup_queue>
    <notification_popup></notification_popup>
    <continue_enqueue
        @continue_clicked="do_nothing"
    ></continue_enqueue>
    <landing_banner></landing_banner>
    <!--    <meta name="og:image" property="og:image" content="https://tinymagichat.com/img/coffee_couch.31f6f1ab.jpg"/>-->
    <!--     og_image="http://localhost:8089/img/old_hands.de2922bb.jpg"-->
    <headers
        title="Register Discount"
        meta_description="Tiny Magic Hat makes it easy to find a quality home care agency in your neighborhood."
        og_title="Find Home Care"
        og_url="https://tinymagichat.com/register_discount"
        og_description="Tiny Magic Hat takes the work out of finding a quality home care agency in your neighborhood."
        og_image="http://tinymagichat.com/img/coffee_couch.31f6f1ab.jpg"
    ></headers>


    <section class="top_section wid_100vw  ">
      <div class="top_section_content ">

        <div v-if="!mobile_mode" class="flex_centered_column">
          <div>
            <img class="padding_tb_20" id="cg_topper_img"
                 src="../../assets/istock/two_smiles.jpg"
                 alt="A blue easy button.">
          </div>
        </div>

        <div class="flex_centered_column right_side">
          <h1 class="text_align_center">Congratulations on your Discount</h1>
          <p class="text_align_center top_text">
            <!--                Finding the right home care agency for your needs doesn't have to been complicated or time consuming.-->

            <!--                Find the best home care agencies in {{ market }} and their current hourly rates in just a few minutes-->
            <!--                before talking with anyone on the phone.-->
            Now you can call your agency and get started right away!

          </p>
          <div class="flex_centered_row flex_wrap neighborhood_list margin_t_20">

          </div>


        </div>
      </div>
    </section>

    <section class="flex_centered_row" v-if="!isValidPhoneNumber">
      <h3>Sorry, but this page has expired.</h3>
    </section>

    <section v-if="isValidPhoneNumber" class="flex_centered_column">
      <div class="flex_centered_row">
        <h3 class="text_align_center margin_t_40 trouble">Trouble with the Certificate Download?</h3>
      </div>

      <p class="font_weight_800 call_us_box">Download Again</p>
      <basic_text_button
          class="padding_lr_6  margin_t_20 margin_b_40"
          :hover_color=colors.cyanic_blue
          :hover_text_color=colors.white
          :button_state="true"
          :button_color="colors.new_orange_main"
          :text_color=colors.white
          :button_color_not_ready="colors.eli_gray"
          :button_not_ready_text_color="colors.pennie_gray"
          button_height="52px"
          button_width="150px"
          border_rad_pct="0"
          text="Get Certificate"
          text_size="14px"
          :bold="true"
          event_name="download_cert_clicked"
          @download_cert_clicked="download_cert"
          v-on:keyup.enter="download_cert"
          tabindex="0"
      ></basic_text_button>
    </section>

    <section v-if="isValidPhoneNumber">
      <div class="flex_centered_row">
        <h3 class="text_align_center margin_t_10 trouble">Ready to call the agency?</h3>
      </div>
      <div  class="contact_info_box flex_centered_row">
        <div class="flex_centered_column call_us_box">
          <call_us_box
              class="margin_lr_10 "
              text="Call the Agency"
              :click_to_call_mode=mobile_mode
              :phone_number="formatted_agency_phone"
              @call_clicked=call_agency
          ></call_us_box>
        </div>

      </div>
    </section>


  </div>
  <outside_footer class="footer_c margin_t_50"></outside_footer>
</template>

<script>
/* eslint-disable */
import {
  colors,
  scroll_to_top,
  handleDiscountCertificate, formatPhoneNumber,
} from "@/library";
import {mapGetters} from "vuex";
import axios from "axios";
import landing_banner from "@/components/parts/landing_banner.vue";
import error_popup_queue from "@/components/basics/error_popup_queue.vue";
import notification_popup from "@/components/basics/notification_popup.vue";
import headers from "@/components/parts/headers.vue";
import Continue_enqueue from "@/components/basics/continue_enqueue.vue";
import input_with_label from "@/components/parts/input_with_label.vue";
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure";
import inside_banner from "@/components/parts/inside_banner";
import spinner from "@/components/parts/spinner";
import basic_text_button from "@/components/parts/basic_text_button";
import store from "@/store";
import outside_footer from "@/components/parts/outside_footer.vue";
import call_us_box from "@/components/SpyGlass/call_us_box.vue";

export default {
  name: "congrats_on_discount",
  async mounted() {
    scroll_to_top()
    this.run_window_width_adjustments(this.windowWidth)
    await this.create_formatted_agency_phone()
  },

  props: [],
  components: {
    input_with_label,
    Continue_enqueue, headers, notification_popup, error_popup_queue,
    landing_banner,
    spinner,
    basic_text_button,
    basic_page_infrastructure,
    inside_banner,
    outside_footer,
    call_us_box
  },
  data() {
    return {
      // id_: "",
      button_wid_: 250,
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
      agency_phone: "",
      agency_unformatted: ""
      // test_list: ['RED', 'YELLOW', 'BLUE', 'GREEN', 'PURPLE', 'PINK', 'WHITE', 'BLACK'],
      // selection_: "Select State and Market",
    }
  },
  methods: {
    do_nothing(something) {
      console.log(`do nothing with ${something}`)
      console.log(something)
    },
    call_agency() {
      // console.log("in caller")
      let num = this.unformatted_agency_phone
      let full_num = 'tel:' + num
      console.log(`Calling ${this.unformatted_agency_phone}`)
      window.open(full_num);
    },

    async download_cert() {
      console.log("Inside download the cert again")
      let build_id = await store.dispatch("get_cache_value", 'new_client_discount_id')
      console.log(`The build id is...${build_id}`)
      handleDiscountCertificate(build_id, true)
    },
    run_window_width_adjustments(new_w) {
      if (this.num_is_between_inclusive(100, 300, new_w)) {
        this.button_wid_ = 250
        this.mobile_mode_x = true
      }
      if (this.num_is_between_inclusive(301, 500, new_w)) {
        this.mobile_mode_x = true
      }
      if (this.num_is_between_inclusive(501, 700, new_w)) {
        this.mobile_mode_x = true
      }
      if (this.num_is_between_inclusive(501, 900, new_w)) {
        this.mobile_mode_x = true
      }
      if (this.num_is_between_inclusive(901, 1199, new_w)) {
        this.mobile_mode_x = false
      }
      if (this.num_is_between_inclusive(1200, 9000, new_w)) {
        this.button_wid_ = 300
        this.mobile_mode_x = false
      }

    },
    num_is_between_inclusive(bottom, top, num) {
      return bottom <= num && top >= num;
    },

    simple_route_prep(r) {
      let this_route = `/${r}`
      store.commit('save_latest_route', this_route)
      this.$router.push({path: this_route})
    }
    ,
    async create_formatted_agency_phone() {
      let agency_phone = await store.dispatch("get_cache_value", 'new_client_discount_agency_phone')
      this.agency_phone = formatPhoneNumber(agency_phone)
      this.agency_unformatted = agency_phone
    },

  },
  computed: {
    ...mapGetters([]),
    id() {
      return this.id_
    },
    formatted_agency_phone() {
      return this.agency_phone
    },
    unformatted_agency_phone() {
      return this.agency_unformatted
    },
    isValidPhoneNumber() {
      // Check if this.phone_number is a string of exactly 10 digits
      return /^\d{10}$/.test(this.agency_unformatted);
    },

    button_wid() {
      return `${this.button_wid_}px`
    },
    selection() {
      return this.selection_
    },

    spinner_up() {
      return store.getters.get_spinner_state
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    mobile_mode() {
      return this.mobile_mode_x
    },


  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>
<style scoped>
@import '../../common.css';

@media (min-width: 100px) {

  .trouble {
    width: 90%;
    max-width: 350px;
  }

  .call_us_box {
    margin-top: -20px;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding: 8px;
    cursor: pointer;
  }


  h1 {
    font-size: 40px;
    color: white;
    width: 85%;
    margin-bottom: 30px;
  }

  h2 {
    font-size: 32px;
    color: var(--primary-purple);
    width: 75%;
    margin-bottom: 30px;
  }

  .top_explainer p {
    text-align: center;
  }


  .neighborhood_list {
    max-width: 90%;
  }


  h3 {
    font-size: 27px;
    padding-top: 50px;
    padding-bottom: 50px;
    /*padding-right: 20px;*/
    /*padding-left: 20px;*/
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    padding-top: 80px;
    padding-bottom: 0;
    max-width: 280px;
    /*margin-bottom: -20px;*/
  }

  p {
    font-size: 20px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .top_section {
    background: linear-gradient(180deg, var(--new-primary-purple-very-deep), var(--new-primary-purple-deep) 40.0%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;

  }

  .top_section_content {
    max-width: 90%;
    padding-bottom: 50px;
    padding-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .right_side h3 {
    color: white;
  }

  .right_side p {
    color: white;
  }


  #cg_topper_img {
    width: 350px;
  }
}


@media (min-width: 360px) {

}

@media (min-width: 400px) {
  .below_carousel h3 {
    padding-top: 80px;
    padding-bottom: 0;
    margin-bottom: 100px;
    font-size: 30px;
  }
}

@media (min-width: 500px) {

  /*.top_explainer {*/
  /*  margin-top: 80px;*/
  /*}*/
  h3 {
    font-size: 37px;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 20px;
    padding-left: 20px;
  }

  p {
    font-size: 25px;
    padding-right: 10px;
    padding-left: 10px;
  }


}


@media (min-width: 750px) {

  .top_text {
    max-width: 85%;
  }

  .top_section_content {
    max-width: 685px;
    padding-bottom: 50px;
    padding-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

}

@media (min-width: 1000px) {

  .trouble {
    width: 90%;
    max-width: 500px;
  }

  .top_section_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  //max-width: 90;

    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
  }


  .second_card h3 {
    max-width: 250px;
  }


  #cg_topper_img {
    width: 450px;
  }


  .below_carousel h3 {
    color: var(--primary-purple);
    padding-top: 80px;
    padding-bottom: 0;
    max-width: 350px;
    font-size: 35px;
    /*margin-bottom: -20px;*/
  }


}

@media (min-width: 1200px) {


  .right_side {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .right_side h3 {
    width: 390px;
    text-align: center;
    font-size: 42px;
  }

  #cg_topper_img {
    width: 700px;
  }

  .top_section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    margin-top: 100px;
    font-size: 38px;
  }


  .top_text {
    max-width: 800px;
    margin-left: 20px;
    line-height: 1.4;

  }


}

@media (min-width: 2000px) {


  .top_section_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  //width: 1450px;

    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
  }


}

</style>