<template>
  <module_main_page
      module_download_name="58a260c1-fd78-4137-ba85-8aae86793037"
      module_logo_name="sd_uti.svg"
      :module_desc_text=module_text
      module_headline="Urinary Tract Infections"
  ></module_main_page>
</template>

<script>
import module_main_page from "@/components/SpeedyDelivery/module_pages/module_main_page";

export default {
  name: "uti",
  data() {
    return {
      module_text: "A Urinary Tract Infection (UTI) is a common bacterial infection that can affect any part of the urinary system, including the bladder, urethra, and kidneys. UTIs are often characterized by symptoms such as frequent urination, a burning sensation during urination, cloudy or bloody urine, and lower abdominal pain. Caregivers need to be knowledgeable about UTIs as they are prevalent among the elderly and individuals with certain medical conditions. Caregivers should encourage proper hygiene, including regular perineal care, to help prevent UTIs. Encouraging clients to stay hydrated and maintain a regular toileting schedule can also be helpful.",
    }

  },
  components: {
    module_main_page
  },
}
</script>

<style scoped>

</style>



